import { Component, OnInit, NgModule } from '@angular/core';
import { NgbModal, NgbModalConfig, NgbModalRef, NgbModule } from "@ng-bootstrap/ng-bootstrap";;
import { BrowserModule } from '@angular/platform-browser';


@Component({
  selector: 'app-audit',
  templateUrl: './audit.component.html',
  styleUrls: ['./audit.component.scss']
})
export class AuditComponent implements OnInit {
  isFormSubmitted: boolean;
  // private modalService: NgbModal

  public currEnv;

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void {
    let currURL = window.location.origin;
    console.log(currURL);

    // dev, qa, poc, live


    if (currURL.includes("localhost")) {
      // REPORTINNG ON THE DEV ENVIROMENT
      this.currEnv = "DEV";
    } else if (currURL.includes("wholeschoolimprovementqa")) {
      // REPORTINNG ON THE QA ENVIROMENT
      this.currEnv = "QA";
    } else if (currURL.includes("wholeschoolimprovement.azurewebsites")) {
      // REPORTINNG ON THE POC ENVIROMENT
      this.currEnv = "POC";
    } else if (currURL.includes("gauteng.gov.za")) {

      // REPORTINNG ON THE LIVE SITE
      this.currEnv = "PROD";

    }
    console.log(this.currEnv);
    // this.openModal('auditmodel');


  }


  openModal(auditmodel) {

    this.modalService.open(auditmodel);


  }






}





