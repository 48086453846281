import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AppService {


  constructor() { }




  /* getLoggedInUserRole() {
     return localStorage.getItem("Roles");
    
   getLoggedInUserRole() {
     return localStorage.getItem("rolename");
   }
 
   setLoggedInUserRole(value) {
     localStorage.setItem("rolename", value);
   }
   */

  getLoggedInEmisCode() {
    return localStorage.getItem("emisCode");
  }

  setLoggedInEmisCode(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("emisCode", value);
  }

  getLoggedInDistrictCode() {
    return localStorage.getItem("districtCode");
  }

  setLoggedInDistrictCode(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("districtCode", value);
  }

  getLoggedInUserId() {
    return localStorage.getItem("userId");
  }

  setLoggedInUserId(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("userId", value);
  }

  getIsLoggedInUser() {
    // localStorage.getItem("isLoggedIn")
    if (sessionStorage.getItem("isLoggedIn") == "true")
      return true;
    else {
      sessionStorage.setItem("isLoggedIn", "false");
      return false;
    }

    // return sessionStorage.getItem("isLoggedIn");
  }

  setIsLoggedInUser(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    sessionStorage.setItem("isLoggedIn", value);
  }

  getIsLoggedInUsername() {
    return localStorage.getItem("username");
  }

  setIsLoggedInUsername(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("username", value);
  }
  getIsLoggedInRoleID() {
    return localStorage.getItem("roleId");
  }
  setIsLoggedInRoleID(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("roleId", value);
  }


  getIsLoggedInRoleName() {
    return localStorage.getItem("rolename");
  }
  setIsLoggedInRoleName(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("rolename", value);
  }


  getLoggedInParentId() {
    return localStorage.getItem("parentId");
  }

  setLoggedInParentId(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("parentId", value);
  }

  getUserHasMultipeRoles() {
    return localStorage.getItem("MultipleRoles");
  }

  setUserHasMultipeRoles(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("MultipleRoles", value);
  }
  getLoggedInUserRole() {
    return localStorage.getItem("rolename");
  }

  setLoggedInUserRole(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("rolename", value);
  }

  getLoggedInUserOfficeLevel() {
    return localStorage.getItem("officeLevel");
  }

  setLoggedInUserOfficeLevel(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("officeLevel", value);
  }

  getLoggedInUserDirectorate() {
    return localStorage.getItem("directorate");
  }

  setLoggedInUserDirectorate(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("directorate", value);
  }

  getLoggedInUserBU() {
    return localStorage.getItem("businessUnit");
  }

  setLoggedInSchoolDistrictName(value) {
    localStorage.setItem("NAME", value);
  }

  getLoggedInSchoolDistrictName() {
    return localStorage.getItem("NAME");
  }


  setLoggedBusnissUnit(value) {
    if (value == "null" || value == "undefined") {
      value = "";
    }
    localStorage.setItem("businessUnit", value);
  }

  getHomeLink() {
    return window.location.origin;;
  }

  setLoggedInUserCircuit(value) {
    localStorage.setItem("circuit", value);
  }

  getLoggedInUserCircuit() {
    return localStorage.getItem("circuit");
  }

  setLoggedInUserCluster(value) {
    localStorage.setItem("cluster", value);
  }

  getLoggedInUserCluster() {
    return localStorage.getItem("cluster");
  }


}
