// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


//base_url: "https://localhost:5001/swagger/index.html",
//base_url: "https://wsiserviceapi.azurewebsites.net/",
//base_url: "https://sgbelectionapi-qa.azurewebsites.net/",
// homelink: "https://sgbelectiondev.azurewebsites.net", 
// base_url:  "https://wholeschoolimprovementapi-qa.azurewebsites.net/",
//base_url:  "https://wholeschoolimprovementapi-prod.azurewebsites.net/",
//base_url:  "https://gdewsiserviceapi.azurewebsites.net/",
//base_url:  "https://wsiserviceapi.azurewebsites.net/",
//base_url:  "https://localhost:5001/",

let urllink = window.location.origin;
// "https://wsiapi-prod.azurewebsites.net/ 
// "https://wholeschoolimprovementapi-training.azurewebsites.net/  // -- TRAINING SITE
// "https://wholeschoolimprovementapi-prod.azurewebsites.net/"     // -- POC/PROD SITE
// "https://wholeschoolimprovementapi-qa.azurewebsites.net/"       // -- QA SITE
// "https://wholeschoolimprovementapi.azurewebsites.net/"          // -- LIVE SITE

let base = "https://wholeschoolimprovementapi-training.azurewebsites.net/";
let doc = "https://sgbserviceapi-dev.azurewebsites.net/api/Upload/Document";

// if (urllink.includes("wholeschoolimprovement-prod.azurewebsites.net")) {
//   //PRODUCTION SITE
//   base = "https://wholeschoolimprovementapi-prod.azurewebsites.net/";
//   doc = "https://sgbserviceapi-dev.azurewebsites.net/api/Upload/Document";

// } else if(urllink.includes("localhost")) {
//   //LOCAL SITE
//   base = "https://wholeschoolimprovementapi-prod.azurewebsites.net/";
//   doc = "https://sgbserviceapi-dev.azurewebsites.net/api/Upload/Document";

// } else {
//   //QA SITE
//   base = "https://wholeschoolimprovementapi-qa.azurewebsites.net/";
//   doc = "https://sgbserviceapi-dev.azurewebsites.net/api/Upload/Document";

// }

export const environment = {
  production: false,
  base_url: base,
  homelink: urllink,
  doc_upload: doc
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
//  base_url: "https://sgbserviceapi-dev.azurewebsites.net/"
//  base_url: "https://sgbelectionapi-qa.azurewebsites.net/"