import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { Observable, using } from 'rxjs';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SSEComponents } from '../../../model/sse-components.model';

@Injectable({
  providedIn: 'root'
})
export class SseService {

  localStorage: Storage;

  changes$ = new Subject();

  Header: HttpHeaders = new HttpHeaders({
    'Content-Type': 'application/json',
    'responseType': 'json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, PATCH, OPTIONS',
    'Access-Control-Allow-Headers': 'X-Requested-With, content-type, Authorization'
  });




  constructor(private http: HttpClient) {
    this.localStorage = window.localStorage;
  }

  base_url = environment.base_url;

  uploadLink = this.base_url + "api/Upload/Document";

  getSSEInstrumentAll() {
    return this.http.get(this.base_url + "api/SSEInstrument/GetSSEInstrumentAll");
  }

  getAllDistricts() {
    return this.http.get(this.base_url + "api/district/getdistrictlist");
  }

  createComponent(Data) {
    return this.http.post(this.base_url + "api/SSEComponent/CreateComponent", Data).subscribe(data => {
      console.log('New Component successfully created');

    }, error => {
      alert("Component creation unsuccesful")
      console.log(JSON.stringify(error.json()));
    });
  }

  GetSSEComponentAll() {
    return this.http.get(this.base_url + "api/SSEComponent/GetAllSSEComponent");
  }

  GetSSEComponentByID(CompID) {
    return this.http.get(this.base_url + "api/SSEComponent/GetSSEComponentByID?ID=" + CompID);
  }

  GetSSEComponentByAreaOfEvaluationID(CompID) {
    return this.http.get(this.base_url + "api/ManageComponent/GetSSEComponentByAreaID?ID=" + CompID);
  }

  getSchoolsByDistrict(districtCode) {
    return this.http.get(this.base_url + "api/School/GetSchoolByDistricts?districtcode=" + districtCode);
  }

  getSchoolsByDistrictCode(districtCode) {
    return this.http.get(this.base_url + "api/School/GetSchoolsByDistrict?DistrictCode=" + districtCode);
  }

  getDistrictByCode(districtCode) {
    return this.http.get(this.base_url + "api/District/GetDistrictNameByDistrictCode?DistrictCode=" + districtCode);
  }

  getSchoolByEmisCode(eimscode) {
    return this.http.get(this.base_url + "api/School/GetSchoolByEmisCode?EmisCode=" + eimscode);
  }

  getAllBusinessUnit() {
    return this.http.get(this.base_url + "api/BusinessUnit/GetBusinessUnit");
  }
  getAllAreaOfEvaluation(): Observable<SSEComponents[]> {
    return this.http.get<SSEComponents[]>(this.base_url + "api/AreaOfEvaluation/GetAreaOfEvaluationList");
  }

  GetAreaOfEvaluationById(id) {
    return this.http.get(this.base_url + "api/AreaOfEvaluation/GetAreaOfEvaluationById?Id=" + id);
  }

  getLastSSEInstrument() {
    return this.http.get(this.base_url + "api/SSEInstrument/GetSSEInstrumentLastID");
  }

  getSSEInstrument() {
    return this.http.get(this.base_url + "api/SSEInstrument/GetSSEInstrument");
  }
  getSSEInstrumentByID(id) {
    return this.http.get(this.base_url + "api/SSEInstrument/GetSSEInstrumentById?Id=" + id);
  }

  createInstrument(instrumentInfo) {
    //console.log(JSON.stringify(createProfile));
    return this.http.post(this.base_url + "api/SSEInstrument/CreateSSEInstrument", instrumentInfo).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("SSE creation unsuccesful")
      console.log(JSON.stringify(error.json()));
    });
  }

  createSelectedKPI(KPIInfo) {
    //console.log(JSON.stringify(createProfile));
    return this.http.post(this.base_url + "api/SSEInstrument/CreateSSESelectedKPI", KPIInfo).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Selected KPI creation unsuccesful")
      console.log(JSON.stringify(error.json()));
    });
  }

  updateReviewSE(updateReviewSSE) {
    return this.http.patch(this.base_url + "api/SSEInstrument/UpdateReviewSSE", updateReviewSSE).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Review SSE not successful")
    });
  }

  GetAllSSEQuestion() {
    return this.http.get(this.base_url + "api/ManageKPI/GetAllKPIQuestion");
  }

  GetAllSSEQuestionBYID(instrimentID) {
    return this.http.get(this.base_url + "api/SSEQuestions/GetKPIQuestionByID?instrumentId=" + instrimentID);
  }

  createSSEQuestions(QuestionInfo) {
    return this.http.post(this.base_url + "api/SSEQuestions/CreateSSEQuestions", QuestionInfo).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("SSE creation unsuccesful")
      console.log(JSON.stringify(error.json()));
    });
  }



  GetAllKPIQuestion() {
    return this.http.get(this.base_url + "api/KPIQuestion/GetAllKPIQuestion");
  }

  GetAllKPIQuestionBYID(id) {
    return this.http.get(this.base_url + "api/KPIQuestion/GetKPIQuestionByID?areaOfEvaluationID=" + id);
  }

  GetKPIQuestionBYAreaOfEvaluationID(areaofevaluationid) {
    return this.http.get(this.base_url + "api/KPIQuestion/GetKPIQuestionByAreaOfEvaluationID?areaOfEvaluationID=" + areaofevaluationid);
  }

  createKPIQuestion(KPIInfo) {
    return this.http.post(this.base_url + "api/KPIQuestion/CreateKPI", KPIInfo).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("KPI creation unsuccesful")
      console.log(JSON.stringify(error.json()));
    });
  }
  GetCompletedSSE() {
    return this.http.get(this.base_url + "api/CompleteSSE/GetCompleteSSE");
  }

  updateCompletedSSE(updateCompleteSSE) {
    return this.http.patch(this.base_url + "api/CompleteSSE/UpdateCompleteSSE", updateCompleteSSE).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Complete SSE update not successful")
      //console.log(JSON.stringify(error.json()));
    });
  }

  updateBUCompletedSSE(updateBUCompleteSSE) {
    return this.http.patch(this.base_url + "api/CompleteSSE/UpdateBUCompleteSSE", updateBUCompleteSSE).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Complete SSE update not successful")
      //console.log(JSON.stringify(error.json()));
    });
  }

  updateReviewCompleteSSE(updateReview) {
    return this.http.patch(this.base_url + "api/CompleteSSE/UpdateReviewCompleteSSE", updateReview).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Review not successful")
      // console.log(JSON.stringify(error.json()));
    });
  }

  get isLocalStorageSupported(): boolean {
    return !!this.localStorage
  }

  getInstrumentName() {
    return localStorage.getItem("instrumentName");
  }

  GetAllLegislations() {
    return this.http.get(this.base_url + "api/ManageLegislation/GetLegislationAll");
  }

  GetAllKpis(userId, emisNumber) {
    return this.http.get(this.base_url + "api/SSEInstrumentTool/GetInstrumentToolKPIsV2?userId=" + userId + "&emisnumber=" + emisNumber);
  }


  GetAllKpi() {
    // return this.http.get(this.base_url + "api/SSEInstrumentTool/GetInstrumentToolKPIs");GetInstrumentToolKPIsV2
    return this.http.get(this.base_url + "api/SSEInstrumentTool/GetInstrumentToolKPIsV2");
  }

  GetKPISchoolResponseAll(emisNumber) {
    return this.http.get(this.base_url + "api/SchoolKPIs/GetKPISchoolResponseAll?emisNumber=" + emisNumber);
  }


  CheckIfSSEExists(emisNumber) {
    return this.http.get(this.base_url + "api/SchoolKPIs/CheckIfSSEExists?emisNumber=" + emisNumber);
  }


  GetAllSchoolKPIBySchool(emisNumber) {
    return this.http.get(this.base_url + "api/SchoolKPI/GetAllKpiBySchool?emisnumber=" + emisNumber);
  }

  addKpi(addkpi: any) {
    return this.http.post(this.base_url + "api/KPI/AddKPI", addkpi).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Add kpi not successful")
      // console.log(JSON.stringify(error.json()));
    });
  }

  updateKPI(updateKPI) {
    return this.http.patch(this.base_url + "api/KPI/UpdateKPI", updateKPI).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("KPI update not successful")
      // console.log(JSON.stringify(error.json()));
    });
  }

  getAllKPIInstrumentTools() {
    return this.http.get(this.base_url + "api/SSEInstrumentTool/GetInstrumentToolList");
  }

  createSSE(createSSE) {
    return this.http.post(this.base_url + "api/SchoolKPIs/CreateResponse", createSSE);
  }

  saveSSE(sse) {
    return this.http.post(this.base_url + "api/SchoolKPIs/saveSSE", sse, { headers: this.Header });
  }



  SIPLogged(createSSE) {
    return this.http.post(this.base_url + "api/SchoolKPIs/SIPLogged", createSSE);
  }

  PublishSSE(createSSE) {
    return this.http.post(this.base_url + "api/SchoolKPIs/PublishSSE", createSSE);
  }

  CreateKPIInstrumentTools(addkpi: any) {
    return this.http.post(this.base_url + "api/SSEInstrumentTool/CreateInstrumentTool", addkpi).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("Add kpi not successful")
      // console.log(JSON.stringify(error.json()));
    });
  }

  UpdateKPIInstrumentTools(updateKPI) {
    return this.http.patch(this.base_url + "api/SSEInstrumentTool/UpdateInstrumentTool", updateKPI).subscribe(data => {
      console.log('posted record');

    }, error => {
      alert("KPI update not successful")
      // console.log(JSON.stringify(error.json()));
    });
  }

  GetKPISchoolResponseTotals(id, emiscode) {
    return this.http.get(this.base_url + "/api/SchoolKPIs/GetKPISchoolResponseTotals?AreaOfEvaluationID=" + id + "&EmisNumber=" + emiscode);
  }


  updateSchoolStatus(emiscode, status) {
    return this.http.post(this.base_url + "/api/School/UpdateSchoolByEmisNumber?EmisNumber=" + emiscode + "&varstatus=" + status, { Header: this.Header });
  }

  getUsersByDistrict(districtcode) {
    return this.http.get(this.base_url + "api/Users/GetUsersByDistrict?DistrictCode=" + districtcode)
  }

  getUsersBySchool(emiscode) {
    return this.http.get(this.base_url + "api/Users/GetUsersBySchool?EmisCode=" + emiscode)
  }


  SendGenericEmail(subject, email, message) {
    return this.http.post(this.base_url + "api/Mail/SendGenericEmail?Subject=" + subject + "&ToAddress=" + email + "&Body=" + message, { headers: this.Header })
  }

  SendGenericEmailBulk(subject, email, message) {
    return this.http.post(this.base_url + "api/Mail/SendGenericEmailBulk?Subject=" + subject + "&Body=" + message, email, { headers: this.Header })
  }

  SendSMS(mobilenumber, message) {
    return this.http.post(this.base_url + "api/SMS/SendMessage?MobileNumber=" + mobilenumber + "&Message=" + message, { headers: this.Header })
  }

  getAllUsers() {
    return this.http.get(this.base_url + "api/Users/GetUserList");
  }

  getSchoolKPIByBusinessUnit(emisNumber, unit) {
    return this.http.get(this.base_url + "api/SchoolKPIs/GetKPISchoolResponseAll?emisNumber=" + emisNumber, { headers: this.Header });
    // return this.http.get(this.base_url + "api/SchoolKPIs/GetKPISchoolResponseAll?emisNumber=" + emisNumber + "&Message=" + unit);
  }

  saveSGBSSEComment(sse) {
    return this.http.post(this.base_url + "api/KPIComments/CreateComment", sse, { headers: this.Header });
  }

  getSGBCommentByKPIId(id) {
    return this.http.get(this.base_url + "api/KPIComments/GetKPICommentsBySchoolKPI?KPIID=" + id, { headers: this.Header });
  }

  getSGBCommentBySchool(code) {
    return this.http.get(this.base_url + "api/KPIComments/GetKPICommentsBySchool?emisNumber=" + code, { headers: this.Header });
  }

  getSGBCommentByUserId(id) {
    return this.http.get(this.base_url + "api/KPIComments/GetKPICommentsByUser?UserID=" + id, { headers: this.Header });
  }

  getAllSGBComments() {
    return this.http.get(this.base_url + "api/KPIComments/GetKPICommentsAll", { headers: this.Header });
  }

  getAllCircuits() {
    return this.http.get(this.base_url + "api/Circuit/GetAllCircuits", { headers: this.Header });
  }

  getAllRegions() {
    return this.http.get(this.base_url + "api/Region/GetAllRegions", { headers: this.Header });
  }

  getAllClusters() {
    return this.http.get(this.base_url + "api/Circuit/GetAllClucters", { headers: this.Header });
  }

  saveDistrictSSE(sse) {
    return this.http.post(this.base_url + "api/SchoolKPIs/updateSSEDistrictCommentById", sse, { headers: this.Header });
  }


  saveSGBComment(sse) {
    return this.http.post(this.base_url + "api/SchoolKPIs/SaveSGBComment", sse, { headers: this.Header });
  }


  getSGBSchoolComments(emis) {
    return this.http.get(this.base_url + "api/SchoolKPIs/GetSGBCommentBySchool?EmisNumber=" + emis, { headers: this.Header });
  }


  getSGBCommentByType(emis, type) {
    return this.http.get(this.base_url + "api/SchoolKPIs/GetSGBCommentByType?EmisNumber=" + emis + "&CommentType=" + type, { headers: this.Header });
  }

  // saveDistrictComment(sse) {
  //   return this.http.post(this.base_url + "api/SchoolKPIs/SaveSGBComment", sse, { headers: this.Header });
  // }


  getAllocationListByDistrict(code) {
    return this.http.get(this.base_url + "api/ManageKPI/GetKPIAllocationByDistrict?DistrictCode=" + code, { headers: this.Header });
  }

  getAllocationListByUserId(id) {
    return this.http.get(this.base_url + "api/ManageKPI/GetKPIAllocationByUserId?UserId=" + id, { headers: this.Header });
  }


  getAllocationListByUserType(type) {
    return this.http.get(this.base_url + "api/ManageKPI/GetKPIAllocationByUserType?UserType=" + type, { headers: this.Header });
  }


  reassignSingleKPI(data) {
    return this.http.post(this.base_url + "api/ManageKPI/AllocateSingleKPI", data, { headers: this.Header });
  }



  assignBatchKPI(data) {
    return this.http.post(this.base_url + "api/ManageKPI/BatchAllocatedKPI", data, { headers: this.Header });
  }

  selfReassignSingleKPI(data) {//
    return this.http.post(this.base_url + "api/ManageKPI/SelfReassignKPI", data, { headers: this.Header });
  }


  updateSSEVerifyStatus(data, status, emis) {//
    return this.http.post(this.base_url + "api/SchoolKPIs/UpdateVerificationStatus?buVerifyStatus=" + status + "&EmisNumber=" + emis, data, { headers: this.Header });
  }



  getSchoolSSEList(code) {
    return this.http.get(this.base_url + "api/SchoolKPIs/GetAllSchoolSSECaptured?DistrictCode=" + code, { headers: this.Header });
  }


}
