import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Renderer2, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

import MetisMenu from 'metismenujs/dist/metismenujs';

import { MENU } from './menu';
import { MenuItem } from './menu.model';
import { Router, NavigationEnd } from '@angular/router';
import { AuthService } from '../../pages/auth/auth.service';
import { AppService } from 'src/app/app.service';
import { UsersService } from "../../pages/users/users.service";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;

  menuItems = [];
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  currentRole;
  currentUsername;
  isShown: boolean;
  public userinfo: any
  public userOffice: any
  public userDirectorate: any
  public user: any
  public usersubDirectorate: any
  public userBranch: any
  public userBusinessUnit: any

  constructor(@Inject(DOCUMENT) private document: Document, private routerlink: Router, private renderer: Renderer2, router: Router, private authService: AuthService, private appService: AppService, private userservice: UsersService) {

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {

        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }

      }
    });
  }

  ngOnInit(): void {
    this.menuItems = MENU;
    // console.log(this.menuItems)
    // this.userOfficeLevel = this.appService.getLoggedInUserOfficeLevel();

    if (!this.appService.getLoggedInUserId() && !this.appService.getLoggedInUserRole()) {
      this.routerlink.navigate(['/auth/login'])
    }

    this.userinfo = this.appService.getLoggedInUserId();
    this.userBusinessUnit = this.appService.getLoggedInUserBU();
    this.userOffice = this.appService.getLoggedInUserOfficeLevel();
    this.userDirectorate = this.appService.getLoggedInUserDirectorate();


    if (this.appService.getIsLoggedInUsername() == "null" || this.appService.getIsLoggedInUsername() == "undefined" || !this.appService.getIsLoggedInUsername()) {
      this.currentUsername = "";
    } else {
      this.currentUsername = this.appService.getIsLoggedInUsername();
      this.menuItems[0].username = this.appService.getIsLoggedInUsername();
      this.menuItems[0].officeLevel = this.appService.getLoggedInUserOfficeLevel();
      this.menuItems[0].infoName = this.appService.getLoggedInSchoolDistrictName();
    }

    this.authService.getUserRoles().then((userRoles: string[]) => {
      this.currentRole = this.appService.getLoggedInUserRole();
      console.log(this.currentRole);
    });

    console.log(this.userinfo);
    this.userservice.getUserId(this.userinfo).subscribe((data: any) => {
      console.log("The currecent user for management", data)
      this.user = data;
      // this.userOffice = this.user.officeLevel;
      // this.userDirectorate = this.user.directorate;
      this.usersubDirectorate = this.user.subDirectorate;
      this.userBranch = this.user.branch;
      // this.userBusinessUnit=this.user.businessUnit;



    })

    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    const desktopMedium = window.matchMedia('(min-width:992px) and (max-width: 1199px)');
    desktopMedium.addListener(this.iconSidebar);
    this.iconSidebar(desktopMedium);
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }


  /**
   * Toggle settings-sidebar 
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }


  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.add("open-sidebar-folded");
      this.isShown = !this.isShown;
    }
  }


  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      this.document.body.classList.remove("open-sidebar-folded");
      this.isShown = !this.isShown;
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e) {
    if (e.matches) {
      if (this.document) {
        this.document.body.classList.add('sidebar-folded');
      }
    } else {
      if (this.document) {
        this.document.body.classList.remove('sidebar-folded');
      }
    }
  }


  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
  }


  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: MenuItem) {
    return item.subItems !== undefined ? item.subItems.length > 0 : false;

  }


  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }


  /**
   * Resets the menus
   */
  resetMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  };


  /**
   * Toggles the menu items
   */
  activateMenuItems() {

    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      // tslint:disable-next-line: no-string-literal
      if (window.location.pathname === links[i]['pathname']) {

        menuItemEl = links[i];

        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  };

  showName(i) {
    if (i == 0) {
      return true;
    } else {
      return false;
    }
  }

  isHO(role) {

    if (role == "HO") {
      return "HOD"
    } else if (role == "SGB") {
      return "SGB_MEMBER"
    } else if (role == "CES" && this.usersubDirectorate == "School Improvement Planning" && this.userDirectorate == "Public Ordinary Schools") {
      return "CES_MEMBER"
    } else if (role == "CES") {
      return "CES_OTHER"
    } else if (role == "Director") {
      return "Director_MEMBER"
    } else if (this.userBusinessUnit && this.userOffice == "District") {

      if (role == "DCES" && this.userBusinessUnit.includes("IDSO/Cluster Leader")) {
        return "IDSO"
      } else if (role == "CES" && this.userBusinessUnit.includes("Circuit Team Manager - CTM")) {
        return "Circuit Manager"
      } else {
        return role
      }

    } else if (this.userBusinessUnit && role == "CES" && this.userOffice == "District" && this.userBusinessUnit == "Circuit Team Manager - CTM") {
      return "Circuit Manager"
    } else {
      return role
    }



  }


  isNewHO(role, sub, dir, bu, office) {

    if (role == "HO" || role == "HOD") {
      return "HOD"
    } else if (role == "SGB") {
      return "SGB_MEMBER"
    } else if (role == "CES" && sub == "School Improvement Planning" && dir == "Public Ordinary Schools") {
      return "CES_MEMBER"
    } else if (role == "CES") {
      return "CES_OTHER"
    } else if (role == "Director") {
      return "Director_MEMBER"
    } else if (bu && office == "District") {

      if (role == "DCES" && bu.includes("IDSO/Cluster Leader")) {
        return "IDSO"
      } else if (role == "CES" && bu.includes("Circuit Team Manager - CTM")) {
        return "Circuit Manager"
      } else {
        return role
      }

    } else if (bu && role == "CES" && office == "District" && bu == "Circuit Team Manager - CTM") {
      return "Circuit Manager"
    } else {
      return role
    }



  }

  altCTMRoleName(role) {
    // let out;

    if (role == "CES") {
      return "Circt Manager";
    } else {
      return role
    }

  }

}
